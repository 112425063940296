import Vue from 'vue'
import VueCurrencyFilter from 'vue-currency-filter'
import {
  Decimal,
} from 'decimal.js'

Vue.use(VueCurrencyFilter, {
  symbol: '$',
  thousandsSeparator: ',',
  fractionCount: 2,
  fractionSeparator: '.',
  symbolPosition: 'front',
  symbolSpacing: false,
})

Vue.filter('phone', (phone) => {
  if (!phone) return ''
  return phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
})


Vue.filter('quantity', (quantity) => {
  const str = quantity.split('.')
  const value = (new Decimal(str[1])).toFixed(0);
  if (value > 0) {
    return quantity
  }
  return (new Decimal(quantity)).toFixed(0);
})

Vue.filter('counter', (counter) => {
  if (!counter) return '∞';
  return counter
})

Vue.filter('highlight', (words, query) => {
  if (query) {
    const check = new RegExp(query.toLowerCase(), 'ig');
    if (words) {
      return words.toString().replace(check, (matchedText) => `<span class="highlight">${matchedText}</span>`);
    } 
    return words;
  } 
  return words;
})
