import cellsimPortalService from '@/api/cellsmart.js';

export default {
  /**
   *  Get token from server
   * @param {} id
   * @param {*} pin
   * @param {*} locationCode
   * @param {*} deviceCode
   * @param {*} deviceName
   */
   getSimInfo(number, params) {
    return cellsimPortalService
      .Api().get(`serialVault/serialNumber/${number}`, {
        params
      })
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  markActive(data) {
    return cellsimPortalService
      .Api().post(`serialVault/markActive`, data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  markPortIn(data) {
    return cellsimPortalService
      .Api().post(`serialVault/markPortIn`, data)
      .then(response => response.data)
      .catch((error) => {
        throw error
      })
  },
  getPlans(params) {
    return cellsimPortalService
      .Api().get(`setting/integration`, {
        params
      })
      .then(response => {
        console.log('response', response)
        if (response.data.data[0].IntegrationSettings && response.data.data[0].IntegrationSettings.metadata) {
          return response.data.data[0].IntegrationSettings.metadata.plans

        } else {
          return null
        }
      })
      .catch((error) => {
        throw error
      })
  },
  randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  },
};