// import organizationSerivce from '@/modules/Organization/service.js'

export default {
    namespaced: true,
    state: {
        Contact: null,
        locationURL: null,
        contactNumber: {
            email: 'mobilesolutionsgroupinc@gmail.com',
            mailto: 'mailto:mobilesolutionsgroupinc@gmail.com',
            number: 'tel: 9179631863',
            label: '(917) 963-1863',
            name: 'Test'
        }
    },
    getters: {
        getLocationURL(state) {
            return state.locationURL;
        },
        getContactNumber(state) {
            return state.contactNumber;
        },
        getContact(state) {
            return state.Contact;
        },
    },
    actions: {
        initLocation({
            commit
        }) {
            var fullUrl = window.location.origin
            console.log('fullUrl', fullUrl)
            const url = fullUrl.split('/').pop().split('#')[0].split('?')[0]
            console.log('url', url)
            commit('setLocationURL', url);
        },
        setContact({
            commit
        }, payload) {
            commit('setContact', payload);
        },
    },
    mutations: {
        setLocationURL(state, payload) {
            state.locationURL = payload;
        },
        setContact(state, payload) {
            state.Contact = payload;
        },
    }
}