<template>
  <v-app >
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import RegisterStoreModule from '@/mixins/RegisterStoreModule';
import globalStore from '@/store.js';

export default {
  name: 'App',
  mixins: [RegisterStoreModule],
  async created() {
    await this.registerStoreModule('global', globalStore);
    console.log('register is done')
    await this.$store.dispatch('global/initLocation');
    
  },
};

</script>

<style lang="scss">
@import '@/scss/variable.scss';
@import '@/scss/app.scss';
.background {
  background: #FFBE0B !important;
}
</style>
