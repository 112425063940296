import Vue from 'vue'
// import VueSocketIO from 'vue-socket.io';
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './plugins/vuex';
import VueProgressBar from './plugins/vue-progressbar'
import './plugins/sweetalert2';
import './plugins/vue-moment';
import './plugins/vue-filter.js';

import './plugins/style';

// Vue.use(
//   new VueSocketIO({
//     debug: process.env.NODE_ENV !== 'production',
//     connection: `${process.env.VUE_APP_SERVER_URL}`,
//     vuex: {
//       store,
//       // @ts-ignore
//       debug: false,
//       actionPrefix: 'SOCKET_',
//       mutationPrefix: 'SOCKET_',
//     },
//   }),
// );
Vue.config.productionTip = false

new Vue({
  vuetify,
  VueProgressBar,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
