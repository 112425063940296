import Vue from 'vue'
import Moment from 'vue-moment'
import momenttimezone from 'moment-timezone'
import moment from 'moment'

Vue.use(Moment, { moment: momenttimezone })

Vue.filter('moment', function (value, format) {
    if (value === null || value === undefined || format === undefined) {
        return ''
    }
    if (format === 'from') {
        return moment(value).fromNow()
    }
    return moment(value).format(format)
})

export default new moment({
});
