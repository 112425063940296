<template>
  <v-container fluid class="touch-view-container">
    <v-card-text style="height: 100%">
      <v-layout row wrap align-center fill-height justify-center>
        <v-flex xs12 v-if="showthis">
          you are on custome site {{getLocationURL}}
        </v-flex>
        <v-flex xs12 sm8 md4 shrink>
          <div class="text-center">
            <div class="text-center">
              <v-img
                alt="Cellsmart Logo"
                class="shrink text-center"
                contain
                src="@/assets/logo.png"
              />
            </div>
            <div class="mt-3">Track Repair Status</div>
            <div class="mt-5">
              <v-text-field
                v-model="trackingNumber"
                outlined
                clearable
                @keydown.enter="checkStatus"
                autofocus
                label="Tracking Number"
              ></v-text-field>
            </div>
            <v-btn
              class="mt-2"
              large
              color="primary"
              @click="checkStatus"
              rounded
              >Submit</v-btn
            >
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-container>
</template>

<script>
import homeService from "@/modules/Home/service.js";
import { mapGetters } from 'vuex';
export default {
  name: "home",
  data() {
    return {
      trackingNumber: null,
    };
  },
  computed: {
    ...mapGetters('global', ['getLocationURL']),
    showthis() {
      if (this.getLocationURL === '4pickup.info') {
        return true
      }
      return false
    }
  },
  methods: {
    checkStatus() {
      this.$router.push({ name: "status", params: { id: this.trackingNumber } });
    },
    reset() {
      this.trackingNumber = null;
    },
    getPlan() {
      this.loadingPlan = true;
      return homeService
        .getPlans()
        .then((response) => {
          this.loadingPlan = false;
          this.Plans = response;
        })
        .catch((error) => {
          console.log("error", error);
          this.loadingPlan = false;
          // this.$swal(
          //   "Sim Status",
          //   "Invalid sim number please check sim number and try again.",
          //   "error"
          // );
        });
    },
  },
};
</script>

<style scoped>
.card-labels {
  display: block;
}

.touch-view-container {
  height: calc(100vh - 105px);
  width: 100%;
  overflow: auto;
}
.noscroll {
  overflow-y: scroll;
}
div::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.selected {
  background: #0f0c60 !important;
}
.cong {
  color: #0f0c60;
}
</style>

